@import '~@holberg/ui-kit/src/scss/core.scss';

.disabled-recording {
    color: #9D9995;

    .arrow-icon {
        path {
            fill: $N300;
        }
    }
}

.enabled-recording {
    cursor: pointer;
    color: $N900;

    &:hover {
        background-color: $N100;
    }

    .arrow-icon {
        path {
            fill: $N800;
        }
    }
}

.recording-box {
    @extend %font-text-medium-mono;

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    padding: 6px 8px;
    margin: 4px 0;
    max-width: 300px;

    &:focus {
        outline: none;
    }

    .recording-info {
        display: flex;
        align-items: center;
        width: 90%;

        .recording-date {
            width: 35%;
        }

        .arrow-icon {
            transform: rotate(90deg);
            margin-right: 10px;
        }
    }

    .recording-info-icon {
        height: 20px;
        outline: none;
        visibility: hidden;

        path {
            fill: $N800
        }
    }

    &:hover .recording-info-icon {
        visibility: visible;
    }
}

.selected-recording {
    background: $Pr100;
}