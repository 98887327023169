@import '~@holberg/ui-kit/src/scss/core.scss';
@import 'components/NavigationHeader/NavigationHeader.module.scss';

$patient-details-overview-height: 40px;
$warning-msg-height : 24px;

.unreported-recordings-section {
    background: $N50;
    padding: 20px 10px 10px 20px;
    overflow: auto;
    width: 100%;
    max-width: 330px;
    max-height: calc(100vh - (#{$patient-details-overview-height} + #{$nav-header-height} + #{$warning-msg-height}));
    border: 2px solid transparent;
    border-right-color: $N100;

    .section-title {
        @extend %font-h2;
        font-weight: 600;
        color: $N900;
        margin: 0;
    }

    .unreported-recordings {
        margin-top: 20px;

        .unreported-recordings-label {
            @extend %font-text-medium;
            color: $N900;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px;

            span {
                &:first-child {
                    width: 44%;
                }

                &:last-child {
                    width: 56%;
                }
            }
        }
    }
}