@import '~@holberg/ui-kit/src/scss/core.scss';


.patient-details-header {
    height: 60px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: $N150;

    .warning-msg {
        width: 100%;

        p {
            margin: 0;
        }
    }

    .main-header {
        padding: 6px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .patient-info {
            display: flex;
            align-items: center;

            .profile-icon {
                margin-right: 4px;
                width: 20px;

                path {
                    fill: $N900;
                }
            }

            .row {
                margin-right: 20px;
            }

            .title {
                @extend %font-text-semibold;

                display: flex;
                align-items: center;
                margin-right: 24px;
                color: $N900;
            }

            .patient-link {
                text-decoration: underline;
                color: $N1000;

                &:hover {
                    color: $N900;
                }
            }

        }

        .switch-wrap {
            align-items: center;
            display: flex;
            position: relative;
            padding: 0 3px;
            border-radius: 5px;

            .switch-text {
                @extend %font-text-medium;

                color: $N900;
                margin-right: 10px;
            }
        }
    }
}